import * as React from "react"
import Layout from "../components/layout"
import Header from "../components/header";
import Footer from "../components/footer";
import WorkComponent from "../components/work-component";


const WorkPage = () => {
    return (
        <main>
            <Header/>
            <Layout>

                <WorkComponent/>

            </Layout>
            <Footer/>
        </main>
    )
}

export default WorkPage
