import React from "react";
import {Link} from "gatsby";
import {email, facebook, instagram, phone} from "../config";

class Header extends React.Component {
    toggleMenu = function(){
        document.getElementById('menu').classList.toggle('open');
        setTimeout(function(){
            document.getElementById('menu').classList.toggle('opened');
        },500)
    }
    render() {
        return (
            <header>
                <script
                    dangerouslySetInnerHTML={{__html: `var sc_project=7349315;
                        var sc_invisible=1;
                        var sc_security="172a48b7";
                        var scJsHost = (("https:" == document.location.protocol) ?
                            "https://secure." : "http://www.");
                        document.write("<sc"+"ript type='text/javascript' src='" +
                            scJsHost+
                            "statcounter.com/counter/counter_xhtml.js'></"+"script>");`
                    }}
                />
                <script
                    dangerouslySetInnerHTML={{__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','GTM-KRRNTWK');`
                    }}
                />
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-Y42NC1XHMP"/>
                <script
                    dangerouslySetInnerHTML={{__html: `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-Y42NC1XHMP');`
                    }}
                />
                <script type="application/ld+json"
                    dangerouslySetInnerHTML={{__html: `
                    {
                        "@context": "https://schema.org",
                        "@type": "Corporation",
                        "name": "Designatives.com",
                        "url": "https://www.designatives.com/",
                        "logo": "",
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "telephone": "+36 30 314 2780",
                            "contactType": "customer service",
                            "availableLanguage": ["en","Hungarian"]
                        },
                        "sameAs": [
                            "https://www.facebook.com/designatives",
                            "https://www.instagram.com/designatives/"
                        ]
                    }`
                    }}
                />

                <div id={'menu'}>

                    <div className={'green-container'}>
                        <div className={'container'}>
                            <Link to={'/'}>
                                <svg className={'logo'} viewBox="0 0 69 82" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Welcome" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g id="MENU" transform="translate(-39.000000, -37.000000)" fill="#000000" stroke="#000000">
                                            <path d="M102.331902,85.3243241 C102.320483,85.4073234 102.313631,85.4910772 102.311347,85.5759628 C101.889605,101.163223 89.2342775,113.373923 73.5,113.373923 C57.6020497,113.373923 44.6680983,100.553933 44.6680983,84.7969015 C44.6680983,69.038738 57.6020497,56.2187481 73.5,56.2187481 C77.8152504,56.2187481 81.9679699,57.1438125 85.8435877,58.9667781 C86.650913,59.346311 87.605163,59.2372802 88.3024849,58.683826 L88.3040075,58.682317 C88.3515867,58.6442128 88.3980241,58.6045995 88.4425583,58.5630998 L102.331902,45.6536971 L102.331902,85.3243241 Z M105.593556,38.1901775 C104.739794,37.8246035 103.747861,37.994752 103.069571,38.6259236 L86.3787595,54.1384849 C82.2975992,52.4479409 77.9728329,51.5926713 73.5,51.5926713 C55.028201,51.5926713 40,66.4876426 40,84.7969015 C40,103.105029 55.028201,118 73.5,118 C82.2884639,118 90.5919999,114.650603 96.8808388,108.569398 C103.074138,102.580624 106.647152,94.5942094 106.966504,86.0452858 C106.988581,85.9173915 107,85.7868563 107,85.6544348 L107,40.3134495 C107,39.3917804 106.447319,38.5568834 105.593556,38.1901775 L105.593556,38.1901775 Z" id="Fill-1"></path>
                                        </g>
                                    </g>
                                </svg>
                            </Link>
                            <div className={'green'}/>
                            <div className="plus" role={'button'} tabIndex={0} onClick={this.toggleMenu} onKeyDown={this.toggleMenu}>
                                <span/>
                                <span/>
                            </div>

                            <div className={'menu-inner'}>
                                <div className={'menu-position'}>
                                    <div className="row">
                                        <div className={'col-12 col-lg-6 pt-4 order-2 order-lg-1'}>
                                            <fieldset>
                                                <legend>Contact us</legend>
                                                <div className={'text'}>
                                                    {email} <br/>
                                                    {phone}
                                                </div>

                                            </fieldset>
                                            <fieldset>
                                                <legend>Follow us</legend>
                                                <div className={'text'}>
                                                    <a href={instagram} target={'_blank'} rel={'noreferrer'} className={'button mb-3'}>Instagram</a>
                                                    <a href={facebook} target={'_blank'} rel={'noreferrer'} className={'button'}>Facebook</a>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div className={'col-12 col-lg-6 order-1 order-lg-2'}>
                                            <ul className={'menu'}>
                                                <li>
                                                    <Link to={'/'} onClick={this.toggleMenu}>Home</Link>
                                                </li>
                                                <li>
                                                    <Link to={'/works'} onClick={this.toggleMenu}>Our Work</Link>
                                                </li>
                                                <li>
                                                    <Link to={'/services'} onClick={this.toggleMenu}>Services</Link>
                                                </li>
                                                <li>
                                                    <Link to={'/about-us'} onClick={this.toggleMenu}>About us</Link>
                                                </li>
                                                <li>
                                                    <Link to={'/contact'} onClick={this.toggleMenu}>Contact</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </header>
        );
    }
}

export default Header;
