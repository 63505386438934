import React from "react";

import image1 from '../works/Able/able-1.png'
import image2 from '../works/Able/able-2.png'
import image3 from '../works/Able/able-3.png'
import image4 from '../works/Able/able-4.png'
import image5 from '../works/Able/able-5.png'
import {Link} from "gatsby";
import WorkArrow from "./workArrow";

class WorkComponent extends React.Component {
    render() {
        return (
            <div className="work-subpage">
                <section className="hero-video">
                    <div className="container">
                    </div>
                </section>
                <section className="work-content-section">
                    <div className="container">
                        <div className="row">
                            <div className={'col-12'}>
                                <h3>Perfect by nature</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className={'col-8'}>
                                <div className={'work-description'}>
                                    <p>The name that we came up with for the brand, Milam, comes from Buddhism and is also referred to as Dream Yoga. We thought that fits perfectly with the brand’s message and its product range. Once the name of the brand was approved we continued to create a logo and apply the design language to the products as well. The brand is subtly present in the mattress and the other products where we used the shape of the logo to create sewing patterns.</p>

                                    <p>The webshop design was created to convey the feeling of an organic, natural product. We used an abundance of photos combined with 3D models of the products along with icons tailored for the product features.</p>

                                    <p>Development wise, the webshop has a custom built back-end and e-commerce module to cater for the different purchasing and order management use cases.</p>
                                </div>
                            </div>
                            <div className={'col-3 offset-1 details'}>
                                <legend>Platform</legend>
                                <ul>
                                    <li>Web</li>
                                </ul>

                                <legend>Deliverables</legend>
                                <ul>
                                    <li>Branding</li>
                                    <li>Web</li>
                                </ul>

                                <legend>Links</legend>
                                <ul>
                                    <li><a href="https://able.com" target={"_blank"} rel={'noreferrer'}>Website</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-12'}>
                            </div>
                        </div>

                        <div className="row">
                            <div className={'col-12'}>

                                <div className={'dn-grid'}>
                                    <div className={'grid-6 item'}>
                                        <Link to={'works/able'}>
                                            <div className={'image'}>
                                                <img src={image1} alt="Able"/>
                                                <div className={'title'}>
                                                    Able
                                                    <WorkArrow/>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className={'grid-6 item'}>
                                        <div className={'image'}>
                                            <img src={image2} alt="Able"/>
                                        </div>
                                    </div>
                                    <div className={'grid-6 item'}>
                                        <div className={'image'}>
                                            <img src={image3} alt="Able"/>
                                        </div>
                                    </div>
                                    <div className={'grid-4 item'}>
                                        <div className={'image'}>
                                            <img src={image4} alt="Able"/>
                                        </div>
                                    </div>
                                    <div className={'grid-6 item'}>
                                        <div className={'image'}>
                                            <img src={image5} alt="Able"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default WorkComponent;
